<template>
  <div class="container">
    <div>
      <div class="search-container">
        <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
        >
          <el-form-item label="用户名">
            <el-input v-model="formInline.username" placeholder="请输入用户昵称"></el-input>
          </el-form-item>
          <el-form-item label="昵称">
            <el-input v-model="formInline.nickname" placeholder="请输入昵称"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="formInline.status" placeholder="请选择">
              <el-option
                  v-for="item of statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="onSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button v-indentify="'sys:admin:view:add'" type="primary" icon="el-icon-plus" @click="handleAdmin('add')">添加管理员</el-button>
      </div>
      <div class="table-container">
        <el-table
            :data="tableData"
            border
            style="width: 100%"
        >
          <el-table-column
              prop="id"
              width="100"
              align="center"
              label="ID">
          </el-table-column>
          <el-table-column
              prop="username"
              label="用户名">
          </el-table-column>
          <el-table-column
              prop="nickname"
              label="昵称">
          </el-table-column>
          <el-table-column
              prop="email"
              label="邮箱">
          </el-table-column>
          <el-table-column
              label="登录信息">
            <template slot-scope="scope">
              <div>登录时间: {{ scope.row.logintime | dateFormat }}</div>
              <div>登录IP: {{ scope.row.loginip }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="创建时间"
              width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.createtime | dateFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="groups_text"
              label="权限组">
          </el-table-column>
          <el-table-column
              width="80"
              align="center"
              label="状态">
            <template slot-scope="scope">
              <StatusTag :state="scope.row.status" :data="statusObject" />
            </template>
          </el-table-column>
          <el-table-column
              width="200"
              align="center"
              label="操作">
            <template slot-scope="scope">
              <el-button v-indentify="'sys:admin:view:edit'" type="primary" class="edit-btn" :underline="false" icon="el-icon-edit" size="small" @click="editAdmin(scope.row)"
                         style="margin-right: 10px;">修改
              </el-button>
              <el-button v-indentify="'sys:admin:view:del'" @click="deleteAdmin(scope.row.id)" slot="reference" type="danger" class="edit-btn" icon="el-icon-delete" :underline="false" size="small"
                         style="margin-right: 10px;">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <PcPaginationView @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :total="total"
                        :page-size="page_size" />
    </div>
    <el-drawer
        :title="`${handleFlag === 'add' ? '添加' : '编辑'}管理员`"
        :visible.sync="showAdminDrawer"
        destroy-on-close
        @close="resetAdminForm"
        direction="rtl">
      <el-form :model="adminForm" label-width="100px" class="dialog-form">
        <el-form-item label="管理员ID" v-if="handleFlag !== 'add'">
          <el-input v-model="adminForm.admin_id" disabled/>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input v-model="adminForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="adminForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="adminForm.email"></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="adminForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="管理员状态">
          <el-switch
              v-model="adminForm.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-text="statusObject['normal'].label"
              :inactive-text="statusObject['disable'].label"
              active-value="normal"
              inactive-value="disable"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="管理员组">
          <el-select v-model="adminForm.group" multiple placeholder="请选择管理员组" style="width: 100%;">
            <el-option
                v-for="item in groupData"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="demo-drawer__footer">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="confirmAdmin()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import { adminIndexApi, adminGroupDataApi, adminAddApi, adminEditApi, adminDelApi } from '@/api/auth'
import Mixin from '@/utils/Mixin'
import { _replaceNBSP } from '@/utils/tools'
import StatusTag from '@/components/pc/common/StatusTag'
import PcPaginationView from '@/components/Pagination/pc'

export default {
  name: 'PcAdminView',
  mixins: [Mixin],
  components: { StatusTag, PcPaginationView },
  data () {
    return {
      statusList: [{
        label: '全部',
        value: ''
      }, {
        label: '正常',
        value: 'normal'
      }, {
        label: '禁用',
        value: 'disable'
      }],
      formInline: {
        username: '',
        nickname: '',
        status: ''
      },
      page: 1,
      page_size: 10,
      total: 0,
      tableData: [],
      groupData: [],
      orderTable: false,
      orderData: [],
      statusObject: {
        normal: {
          type: '',
          label: '正常'
        },
        disable: {
          type: 'danger',
          label: '禁用'
        }
      },
      showAdminDrawer: false,
      handleFlag: '',
      adminForm: {
        admin_id: '',
        username: '',
        password: '',
        email: '',
        nickname: '',
        status: 'normal',
        group: ''
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const jsData = {
        page: this.page,
        page_size: this.page_size,
        ...this.formInline
      }
      const data = await adminIndexApi(jsData)
      if (data.code === 1) {
        this.tableData = data.data.data
        this.total = data.data.total
      }
    },
    async getGroupData () {
      const data = await adminGroupDataApi()
      if (data.code === 1) {
        this.groupData = _replaceNBSP(data.data, 'label')
      }
    },

    confirmAdmin () {
      const { handleFlag } = this
      if (handleFlag === 'add') {
        this.adminAdd()
      } else {
        this.editAdminForm()
      }
    },
    cancelForm () {
      this.showAdminDrawer = false
    },
    resetAdminForm () {
      this.adminForm = {
        admin_id: '',
        username: '',
        password: '',
        email: '',
        nickname: '',
        status: 'normal',
        group: ''
      }
    },
    async adminAdd () {
      const { ...jsData } = this.adminForm
      jsData.group = jsData.group.join(',')
      const data = await adminAddApi(jsData)
      if (data.code === 1) {
        this.$message.success('添加成功')
        this.cancelForm()
        await this.getList()
      }
    },

    // 查询
    onSearch () {
      this.page = 1
      this.getList()
    },
    /**
     * 操作管理员中转
     * @param {String} flag 当前操作的类型
     */
    handleAdmin (flag) {
      this.getGroupData()
      this.showAdminDrawer = true
      this.handleFlag = flag
    },
    /**
     * 编辑管理员数据处理
     * @param {Object} params 当前行的数据
     * @returns {Promise<void>}
     */
    async editAdmin (params) {
      const { id, username, nickname, email, status, groups } = params
      this.adminForm = {
        admin_id: id,
        username,
        nickname,
        email,
        status,
        group: groups.split(',').map(item => parseInt(item))
      }
      this.handleAdmin('edit')
    },
    /**
     * 编辑管理员
     * @returns {Promise<void>}
     */
    async editAdminForm () {
      const { ...jsData } = this.adminForm
      jsData.group = jsData.group.join(',')
      const data = await adminEditApi(jsData)
      if (data.code === 1) {
        this.$message.success('修改成功')
        this.cancelForm()
        await this.getList()
      }
    },
    /**
     * 删除管理员
     * @param {Number} admin_id 管理员ID
     * @returns {Promise<void>}
     */
    async deleteAdmin (admin_id) {
      try {
        await this.$confirm('确定要删除此管理员吗？')
        const data = await adminDelApi({ admin_id })
        if (data.code === 1) {
          this.$message.success('删除成功')
          await this.getList()
        }
      } catch (e) {

      }
    },
    handleSizeChange (val) {
      this.page_size = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
